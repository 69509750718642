<template>
  <div>
    <div class="container">
      <loading-state
          v-if="loading && !loaded"
          title="Loading repair kits to pick"
      />

      <template v-if="jitRepairKits.length">
        <div class="row mb-2">
          <div class="col-md-12 d-flex">
            <label class="mt-2">{{ toBeBuiltText }}</label>
            <div class="ml-auto mr-2 mt-3 cursor-pointer" v-if="!loading" @click="loadJitOrdersAwaitingRepairKits()">
              <icon-refresh size="sm" class="icon-all-white icon-chunky"/>
            </div>
            <div class="ml-auto mr-2 mt-3 cursor-not-allowed" v-if="loading">
              <icon-refresh size="sm" class="icon-all-white icon-chunky fa-spin"/>
            </div>
          </div>
        </div>

        <div class="card-grid vertical">
          <button
              v-for="kit in jitRepairKits"
              :key="`jit-kit-${kit.id}`"
              class="card card-body card-link py-3 justify-content-between flex-row align-items-center"
              @click="actionBuild(kit.id)"
          >
            <span>SO: <strong>{{ kit.service_order.so_number}}</strong></span>
            <span class="badge badge-success badge-sm">Build Repair Kit</span>
          </button>
        </div>
      </template>

      <!-- Empty State -->
      <empty-state
          v-if="jitRepairKits.length < 1"
          emptyIcon
          icon="box"
          title="There are no JIT kits to build"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      loaded: false
    }
  },

  props: ['kitPicked'],

  mounted() {
    this.loadJitOrdersAwaitingRepairKits().then(() => {
      this.loaded = true;
    })
  },

  computed: {
    ...mapGetters({
      jitRepairKits: 'jitRepairKits',
      loading: 'jitRepairKitsLoading'
    }),
    toBeBuiltText() {
      return `Kits to build from JIT Parts (${this.jitRepairKits.length})`;
    }
  },

  methods: {
    ...mapActions([
      'displayToast',
      'loadJitOrdersAwaitingRepairKits',
    ]),
    actionBuild(jitStockOrderId) {
      this.$http.put(`/api/v4/stock/orders/jit/build-pending-kit/${jitStockOrderId}`).then(response => {
        console.log(response);
      })
    }
  }
}
</script>